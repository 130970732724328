import React from 'react'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import OccupationReportCharts from './OccupationReportCharts.controller'

const OccupationReportContent = () => {
  return (
    <div id="occupation-report" className='flex-1'>
      <TabView activeIndex={1}>
        <TabPanel header='Reporte comparativo' disabled>
          <span>&#x2f;&#x2f;Silence is golden</span>
        </TabPanel>
        <TabPanel header='Gráficas'>
          <OccupationReportCharts/>
        </TabPanel>
      </TabView>
    </div>
  )
}

export default OccupationReportContent
