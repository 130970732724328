import React from 'react'
import MainTitle from '../../../components/molecules/titles/MainTitle.molecule'

const FormHeaderCreateClient = ({
  isCreate = true
}) => {
  let label
  if (isCreate) {
    label = 'Crear'
  } else {
    label = 'Consulta de'
  }
  return (
    <MainTitle
      className='mb-4'
      title={`${label} cliente`}
      goBack
    />
  )
}

export default FormHeaderCreateClient
