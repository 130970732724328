/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FormClientDetails from './formSections/FormClientDetails.controller'
import FormClientProjects from './formSections/FormClientProjects.controller'
import FormClientFooter from './formSections/FormClientFooter.controller'
import TabMenuContentContainer from '../../../../components/layouts/containers/TabMenuContentContainer.container'
import { getClient } from '../../../../services/client.service'
import Card from '../../../../components/atoms/Card.atom'
import Divider from '../../../../components/atoms/Divider.atom'
import ProgressSpinner from '../../../../components/atoms/misc/ProgressSpinner.atom'

const FormEditClient = () => {
  const [client, setClient] = useState({
    name: '',
    code: ''
  })
  const [projectsPrevState, setProjectsPrevState] = useState([])
  const [projects, setProjects] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const { clientId } = useParams()

  useEffect(() => {
    gettingClient()
  }, [clientId])

  useEffect(() => {
    console.log(error)
  }, [error])

  const gettingClient = async () => {
    setLoading(true)
    const response = await getClient(clientId)
    if (response.success) {
      setClient(response.result.client)
      setProjects(response.result.projects)
      setProjectsPrevState(response.result.projects)
    }
    setLoading(false)
  }
  if (loading) {
    return (
      <div id='client-form'>
        <Card className='p-0 overflow-hidden flex flex-column gap-3 p-1 shadow-none justify-content-center align-items-center' style={{ height: 'calc(100vh - 245px)' }}>
          <ProgressSpinner/>
        </Card>
      </div>
    )
  }
  return (
    <div id='client-form'>
      <Card className='p-0 overflow-hidden flex flex-column gap-3 p-1 shadow-none'>
        <FormClientDetails
          client={client}
          setClient={setClient}
          isCreate={false}
          projects={projects}
          setProjects={setProjects}
          setError={setError}
        />
        <Divider className='my-3' stroke='sm' />
        <FormClientProjects
          client={client}
          projects={projects}
          setProjects={setProjects}
          isCreate={false}
          error={error}
          setError={setError}
        />
      </Card>
      <FormClientFooter
        client={client}
        projects={projects}
        isCreate={false}
        error={error}
      />
    </div>
  )
}

export default FormEditClient
