import React from 'react'
import Calendar from '../atoms/Calendar.atom'
import PropTypes from 'prop-types'
import { hasAnyErrors } from '../../utils/validations.utils'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import DatePicker from '../atoms/DatePicker.atom'

const InputCalendarForm = (props) => {
  const forId = `input-calendar-${props.label?.replace(/ /g, '-')}`
  return (
    <>
      <label htmlFor={forId} className="font-medium">{props.label}</label>
      <DatePicker
        id={forId}
        placeholder={props.placeholder}
        picker={props.period}
        value={props.value}
        onChange={props.onChange}
        format={props.format}
        inputReadOnly={props.inputReadOnly}
        name={props.name}
        allowClear={props.allowClear}
        maxDate={props.maxDate}
        minDate={props.minDate}
        className={`w-full ${hasAnyErrors(props.error) ? 'p-invalid' : ''} ${props.className}`}
      />

      {hasAnyErrors(props.error) ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} /> : null}
    </>
  )
}

// InputCalendarForm.propTypes = {
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.instanceOf(Date)]),
//   onChange: PropTypes.func,
//   showWeek: PropTypes.bool,
//   minDate: PropTypes.instanceOf(Date),
//   maxDate: PropTypes.instanceOf(Date),
//   name: PropTypes.string,
//   showButtonBar: PropTypes.bool,
//   error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.array]),
//   invalidate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
// }

export default InputCalendarForm
