import React, { useEffect, useState } from 'react'
import TaskChangeLogHeader from './controllers/TaskChangeLogHeader.controller'
import TaskChangeLogTable from './controllers/TaskChangeLogTable.controller'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  validateTaskID as validateTaskIDService
} from '../../services/task.service'

const TaskChangeLog = () => {
  const [loading, setLoading] = useState(true)
  const [isValidTaskId, setIsValidTaskId] = useState(false)
  const [task, setTask] = useState()
  const [prevTaskId, setPrevTaskId] = useState()
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    taskId
  } = state || { taskId: -1 }

  useEffect(() => {
    if (!taskId || taskId === prevTaskId) {
      return
    }
    setPrevTaskId(taskId)
    validateTaskID(taskId)
  }, [taskId])

  const validateTaskID = async (taskId) => {
    setLoading(true)
    try {
      const response = await validateTaskIDService(taskId)
      if (!response.success) {
        throw new Error()
      }
      const isValid = response.result?.id
      if (!isValid) {
        throw new Error('Task not found')
      }
      setIsValidTaskId(true)
      setTask(response.result)
    } catch (error) {
      setIsValidTaskId(false)
      navigate('/404')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <TaskChangeLogHeader
        verifyTaskLoading={loading}
        task={task}
        isValidTaskId={isValidTaskId}
      />
      {
        isValidTaskId &&
        (
          <TaskChangeLogTable
            verifyTaskLoading={loading}
            task={task}
          />
        )
      }
    </>
  )
}

export default TaskChangeLog
