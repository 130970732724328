import React from 'react'
import MainTitle from '../molecules/titles/MainTitle.molecule'
import PrimaryButton from '../molecules/buttons/PrimaryButton.molecule'

/**
 * @param {import("./ContentLayoutHeader.d").ContentLayoutHeaderProps} props
 * @returns {JSX.Element}
 */
const ContentLayoutHeader = ({
  title,
  subtitle,
  buttonProps,
  extraContent
}) => {
  const renderButton = buttonProps && !buttonProps.hidden && (
    <PrimaryButton {...buttonProps} />
  )
  return (
    <div className='flex justify-content-between mb-4' style={{ minHeight: '45px' }}>
      <MainTitle
        title={title}
        subtitle={subtitle}
      />
      <div className='flex justify-content-between gap-2'>
        {renderButton}
        {extraContent}
      </div>
    </div>
  )
}

export default ContentLayoutHeader
