/* eslint-disable */
import React, { useEffect } from 'react'
import useSidebarContext from '../../hooks/contextConsumers/useSidebarContext.hook'
import { useNavigate } from 'react-router-dom'
import { APP_FILTERS_MENU_ROUTES } from '../routes/appFiltersMenuRoutes.router'

const AppFiltersMenu = () => {
  const { keyPath } = useSidebarContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (APP_FILTERS_MENU_ROUTES?.[keyPath]) {
      navigate(keyPath)
    }
  }, [keyPath])

  // this used to contain logic to render a non-used mobile nav menu
  return (
    <></>
  )
}

export default AppFiltersMenu