import React, { useEffect, useState } from 'react'
import { generateBillingChartByDateRange, generateHoursByClientChartByDateRange } from '../../../services/dashboard.service'
import HoursByClientPieChart from '../components/HoursByClientPieChart'
import BilledTimePieChart from '../components/BilledTimePieChart'
const DashboardCharts = ({
  dateRange
}) => {
  const [billingChartData, setBillingChartDate] = useState([])
  const [hoursByClientChart, setHoursByClientChart] = useState([])
  const [billingChartError, setBillingChartError] = useState(null)
  const [hoursChartError, setHoursChartError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getChartsData()
  }, [dateRange])

  const getChartsData = async () => {
    setLoading(true)
    await Promise.all([
      getBillingChartData(),
      getHoursByClientChart()
    ])
    setLoading(false)
  }

  const getBillingChartData = async () => {
    setBillingChartError(null)
    try {
      const response = await generateBillingChartByDateRange(dateRange?.startDate, dateRange?.endDate)
      if (response.success) {
        if (!response.result?.length) {
          setBillingChartDate([])
        } else {
          setBillingChartDate([
            {
              data: response.result
            }
          ])
        }
      } else {
        throw new Error(response.message)
      }
    } catch (error) {
      setBillingChartError(error.message)
    }
  }

  const getHoursByClientChart = async () => {
    setHoursChartError(null)
    try {
      const response = await generateHoursByClientChartByDateRange(dateRange?.startDate, dateRange?.endDate)
      if (response.success) {
        setHoursByClientChart([
          {
            data: response.result.map((item) => ({
              name: item.clientTagName,
              value: item.billedTime,
              color: item.clientColor,
              extraInfo: {
                opacity: 1
              }
            }))
          }
        ])
      } else {
        throw new Error(response.message)
      }
    } catch (error) {
      setHoursChartError(error.message)
    }
  }

  return (
    <>
      <div className='flex gap-3 flex-wrap mb-3'>
        <HoursByClientPieChart
          data={hoursByClientChart}
          loading={loading}
          error={hoursChartError}
        />
        <BilledTimePieChart
          data={billingChartData}
          loading={loading}
          error={billingChartError}
        />
      </div>
    </>
  )
}

export default DashboardCharts
